import React from "react"
import Layout from "../components/layout"
import TakenSVG from "../assets/svgr/taken"
import {linkResolver} from "../utils/link-resolver"


import {
  withPrismicUnpublishedPreview,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'

import BlogTemplate from "../templates/blog-post"
import FlutterBlogTemplate from "../templates/flutter-blog-post";
import HeaderH1 from "../components/headerH1";

const NotFoundPage = () => (
  <Layout>
    <section className="bg-white py-20">
      <div className="container max-w-5xl mx-auto m-8">
        <HeaderH1 headerText="This page is gone" />

        <div className="container mx-auto mt-16" style={{ maxWidth: "300px" }}>
          <TakenSVG color="#58B8BC" />
        </div>
      </div>
    </section>
  </Layout>
)

export default withPrismicUnpublishedPreview(NotFoundPage, [
  {
    repositoryname: 'linkfive',
    linkResolver,
    componentResolver: componentResolverFromMap({
      blog_post: BlogTemplate,
      flutter_post: FlutterBlogTemplate
    }),
  },
])
