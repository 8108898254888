import React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import { GatsbySeo } from "gatsby-plugin-next-seo"

import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Header1 from "../components/header1"

var PrismicDOM = require("prismic-dom")
const {linkResolver} = require("../utils/link-resolver")

function countWords(str) {
  if (str) {
    str = str.replace(/(^\s*)|(\s*$)/gi, "")
    str = str.replace(/[ ]{2,}/gi, " ")
    str = str.replace(/\n /, "\n")
    return str.split(" ").length
  }
  return 150
}

function calcluateReadingTime(str) {
  return Math.round(countWords(str) / 150)
}

function seoImageList(image) {
  if (image && image.url) {
    return {
      url: image.url,
      width: image.dimensions.width,
      height: image.dimensions.height,
    }
  }
  return {}
}

const BlogTemplate = ({ data }) => {
  if (!data) return null

  const node = data.prismicBlogPost
  const document = node.data

  const readingTime = calcluateReadingTime(document.body.text)

  var last_publication_date = PrismicDOM.Date(node.last_publication_date)

  var last_publication_date_formatted = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  }).format(last_publication_date)

  var first_publication_date = PrismicDOM.Date(node.first_publication_date)

  var first_publication_date_formatted = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  }).format(first_publication_date)

  return (
    <Layout fullWave="false" bottomWave="false" callToAction="true">
      <GatsbySeo
        title={`${document.title.text} | LinkFive Blog`}
        description={document.seo_summary.text}
        canonical={"https://www.linkfive.io/blog/" + node.uid}
        openGraph={{
          url: "https://www.linkfive.io/blog/" + node.uid,
          local: node.lang,
          title: document.title.text + " | LinkFive Blog",
          description: document.seo_summary ? document.seo_summary.text : "",
          type: "article",
          article: {
            publishedTime: node.first_publication_date,
            modifiedTime: node.last_publication_date,
          },
          images: [seoImageList(document.hero_image)],
          site_name: "LinkFive Blog",
        }}
      />
      <section className="bg-white py-6 mt-12 bg-l5-primary-background container mx-auto">
        <Link to="/blog" className="inline-block py-2 px-4 text-white font-bold nav-underline">&#x2190; Back to the blog</Link>
      </section>
      <section className="bg-white pb-6">
        {document.hero_image && document.hero_image.url ? (
          <GatsbyImage fluid={document.hero_image.fluid} alt="Hero Image" />
        ) : (
          <></>
        )}

        <div className="container mx-auto px-2 pt-4 pb-12 text-gray-800">
          <Header1 headerText={document.title.text} widthClass="md:w-4/6" />
          <div className="max-w-5xl mx-auto m-8 text-gray-500 md:text-xl longform-text">
            <p className="text-center italic">
              By {document.author_name.text} - Average reading time:{" "}
              {readingTime} minutes
            </p>
            <RichText render={document.body.raw} linkResolver={linkResolver} />
          </div>
        </div>

        <div className="w-2/3 mx-auto pb-20">
          <hr className="border-solid border-2 border-l5-primary mb-5" />
          <p className="text-center text-gray-400">
            {node.last_publication_date !== node.first_publication_date ? (
              <span>
                First published on {first_publication_date_formatted}, last
                updated on {last_publication_date_formatted}
              </span>
            ) : (
              <span>Published on {first_publication_date_formatted}</span>
            )}
            {document.image_credits && document.image_credits ? (
              <span className="italic">
                {" "}
                &#8212; Image credits: {document.image_credits}
              </span>
            ) : (
              <></>
            )}
          </p>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query PageQuery($uid: String) {
    prismicBlogPost(uid: { eq: $uid }) {
      uid
      first_publication_date
      last_publication_date
      lang
      data {
        hero_image {
          fluid {
            ...GatsbyPrismicImageFluid
          }
          dimensions {
            height
            width
          }
          url
        }
        author_name {
          text
        }
        seo_summary {
          text
        }
        title {
          text
        }
        body {
          raw
          text
        }
        image_credits
      }
    }
  }
`

export default withPrismicPreview(BlogTemplate, [
  {
    repositoryName: 'linkfive',
    linkResolver,
  },
])
